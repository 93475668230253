<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons class="mr-2 toolbar-title" slot="start">
          <ion-back-button
            color="primary"
            mode="ios"
            text=""
            :default-href="
              userType === 'b2b'
                ? `/b2b/order/purchase/track-order/${orderId}`
                : `/sale/order/purchase/track-order/${orderId}`
            "
            @click="goBack"
            :icon="chevronBackOutline"
          ></ion-back-button>
        </ion-buttons>
        <ion-label class="head-title">{{ $t('track_driver_location') }}</ion-label>
      </ion-toolbar>
      <div class="grey-lines" />
    </ion-header>
    <ion-content>
      <iframe class="maps" :src="trackingLink" width="101%" height="101%"></iframe>
    </ion-content>
  </ion-page>
</template>
<script>
import { loadingController } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    trackingLink: {
      type: String,
      default: ''
    },
    userType: {
      type: String,
      default: ''
    },
    orderId: {
      type: String,
      default: ''
    }
  },

  emits: ['close-page'],

  mounted() {
    this.showLoading();
  },

  setup(props, { emit }) {
    const isTrackingLinkNotValid = ref(false);
    const goBack = () => {
      emit('close-page');
    };

    // just show loading spinner, it's not related with fetch data process
    const showLoading = async () => {
      const loading = await loadingController.create({
        duration: 3000,
        spinner: 'lines',
        cssClass: 'spinner-maps',
        showBackdrop: false,
        backdropDismiss: false,
        mode: 'ios'
      });

      loading.present();
    };

    return {
      chevronBackOutline,
      isTrackingLinkNotValid,
      goBack,
      showLoading
    };
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
